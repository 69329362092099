import React from 'react';
import logo from '../../src/images/tygrovetechlogo.png';
import sba1 from '../../src/images/8a_certified.png';
import sba2 from '../../src/images/edwosb.png';
import sba3 from '../../src/images/wosb.png';

const Footer = () => (
  <footer className="usa-footer site-footer" role="contentinfo">
    <div className="footer-section-bottom bg-base-lighter">
      <div className="grid-container">
        <div className="grid-row grid-gap text-center padding-3">
          <div className="tablet:grid-col-2 sg-footer-col">
            <div className="logo-links">
              <a className="footer-logo media_link" href="/">
                <img src={logo} width="150" alt="Tygrove Technologies logo" />
              </a>
            </div>
          </div>
          <div className="tablet:grid-col-8 sg-footer-col">
          <img src={sba1} className="padding-1" width="100" alt="8a certified" />
          <img src={sba2} className="padding-1" width="100" alt="edwosb certified" />
          <img src={sba3} className="padding-1" width="100" alt="wosb certified" />
          </div>
          <div className="tablet:grid-col-2 text-left sg-footer-col">
            <ul className="add-list-reset">
              <li>
                <a href="/about">
                  <span>About Us</span>
                </a>
              </li>
              <li>
                <a href="/contracts">
                  <span>Contracts</span>
                </a>
              </li>
              <li>
                <a href="/contact-us">
                  <span>Contact Us</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
